.messageWrapper {
  padding: 10px 20px;
  flex: 1 0 0;
  background-color: #f6f7fb;
  overflow-y: auto;
  /* transform: scaleY(-1); */
}

.list {
  min-height: 100%;
  /* transform: scaleY(-1); */
}

.itemCss {
  display: flex;
  align-items: center;
  padding: 10px 0;
  margin-bottom: 5px;
}

.avatarCss {
  display: flex;
  width: 40px;
  height: 40px;
  align-self: flex-start;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  color: #fafafa;
  border-radius: 50%;
  background-color: #bdbdbd;
}

.avatarImageCss {
  width: 100%;
  height: 100%;
}

.avatarImageCss > img {
  width: 100%;
  height: 100%;
}

.msgCss {
  position: relative;
}

.top {
  position: absolute;
  top: -20px;
  left: 20px;
  display: flex;
  align-items: baseline;
  font-size: 14px;
  color: #666;
}

.name {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.time {
  padding-left: 10px;
  width: 100px;
  font-size: 12px;
  color: #999;
}

.triangle {
  position: absolute;
  width: 15px;
  height: 15px;
  top: 0;
  left: 6px;
  right: 0;
  bottom: 0;
  border: 1px solid #eee;
  background-color: #fff;
  transform-origin: left bottom;
  transform: rotate(45deg);
}

.msgBody {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-left: 15px;
  padding: 8px 10px;
  min-width: 50px;
  max-width: 300px;
  min-height: 33px;
  font-size: 14px;
  color: #333;
  border-radius: 4px;
  background-color: #fff;
  overflow: hidden;
  word-break: break-all;
  word-wrap: break-word;
}

.msgBody > img {
  max-width: 100%;
  cursor: pointer;
}

.itemToCss {
  flex-flow: row-reverse;
}

.itemToCss .triangle {
  top: 0px;
}

.itemToCss .msgBody {
  margin-right: 13px; 
  background-color: #7adb5e;
}

.itemToCss .triangle {
  left: auto;
  right: 12px;
  background-color: #7adb5e;
}

.fileDownload {
  display: flex;
  width: 130px;
  flex-direction: column;
  align-items: center;
}

.filename {
  padding-top: 5px;
  font-size: 16px;
  text-align: center;
  color: #666;
}

.map {
  width: 285px;
  height: 160px;
  cursor: pointer;
}