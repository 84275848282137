.toolIconDl {
  display: flex;
  padding-bottom: 5px;
}

.toolIconItem {
  position: relative;
  padding: 0 8px;
  cursor: pointer;
}

.toolImg {
  width: 25px;
  height: 25px;
}

.textareaCss {
  padding: 5px;
  width: 100%;
  font-size: 14px;
  border: none;
  outline: none;
  resize: none;
}

.buttonLayerCss {
  display: flex;
  align-items: center;
  justify-content:  flex-end;
  font-size: 12px;
  color: #999;
}

.buttonCss {
  margin: 0 10px;
  padding: 4px 20px;
  color: #fff;
  border: none;
  border-radius: 2px;
  background-color: #315aac;
  cursor: pointer;
}

.inputLayer {
  position: relative;
  padding: 10px;
  height: 170px;
  border-top: 1px solid #eee;
}