body {
  background-color: #eee;
}
#App {
  height: 100vh;
}

.flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.align-center {
  align-items: center;
}

.clear {
  &::after {
    clear: both;
    display: block;
    content: ' ';
  }
}