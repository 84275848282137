.badgeLayer {
  position: absolute;
  right: -2px;
  top: -4px;
  padding: 2px 6px;
  font-size: 12px;
  color: #fff;
  text-align: center;
  border-radius: 50px;
  background-color: #DE350B;
}