.messageWrapper {
  position: fixed;
  display: flex;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 920px;
  height: 700px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 1px 1px 50px rgb(0 0 0 / 30%);
  overflow: hidden;
  z-index: 1299;
}

.maskLayer {
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, .5);
  z-index: 10;
}

.maskBody {
  display: flex;
  margin: auto;
  flex-direction: column;
  align-items: center;
}

.maskImage {
  width: 128px;
  height: 128px;
}

.recordTimeText {
  padding-top: 10px;
  color: #fff;
}

.button {
  cursor: pointer;
  margin-top: 10px;
  width: 100px;
  height: 40px;
  font-size: 14px;
  border-radius: 5px;
  border: 0;
}

.btnGroup {
  padding-top: 10px;
}

.btnGroup > button {
  margin: 0 10px;
  color: #fff;
}

.btnGroup .send {
  background: #19be6b;
}

.btnGroup .cancel {
  background: #ed4014;
}

.left {
  display: flex;
  flex-direction: column;
  width: 240px;
  height: 100%;
  border-right: 1px solid #eee;
}

.main {
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
}

.contentLayer {
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  overflow: hidden;
}

.head {
  display: flex;
  height: 50px;
  line-height: 50px;
  align-items: center;
  border-bottom: 1px solid #eee;
  background-color: #f3f3f3;
  cursor: move;
}

.username {
  padding: 0 10px;
  flex: 1 0 0;
}

.close {
  margin-right: 10px;
  padding: 5px;
  border-radius: 50%;
  cursor: pointer;
}

.close:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.searchWrapper {
  display: flex;
  padding: 0 10px;
  height: 50px;
  align-items: center;
  justify-content: space-around;
  border-bottom: 1px solid #eee;
  background-color: #f7f7f7;
}

.searchInput {
  height: 30px;
  padding: 0 5px;
  flex: 1 0 0;
  font-size: 14px;
  border-radius: 2px;
  border: 1px solid #e8e8e8;
  color: #333;

}

.searchInput:focus {
  outline: none;
}

.searchButton {
  margin-left: 8px;
  padding: 3px 10px;
  border: 1px solid #ddd;
  cursor: pointer;
}
