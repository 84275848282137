.previewLayer {
  display: flex;
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  z-index: 10001;
}

.maskLayer {
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(55, 55, 55, .6);
  z-index: 10078;
}

.mapLayer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding: 10px;
  width: 80%;
  height: 80%;
  z-index: 10079;
  background-color: #fff;
}