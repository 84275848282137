.myDraggable {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.empty {
  padding: 10px 0;
  width: 100%;
  font-size: 12px;
  text-align: center;
  color: #666;
}

.chatList {
  flex: 1;
  overflow-y: auto;
}

.chatItem {
  display: flex;
  padding: 8px 10px;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid #e8e8e8;
}

.chatItem:hover, .active {
  background-color: #f7f7f7;
}

.chatItem:last-child {
  border-bottom: none;
}

.chatList {
  flex: 1;
  overflow-y: auto;
}

.avatar {
  position: relative;
  display: flex;
  width: 50px;
  height: 50px;
  align-items: center;
  justify-content: center;
  color: #fafafa;
  border-radius: 50%;
  background-color: #bdbdbd;
}

.avatar .image, .avatar .image > img {
  width: 100%;
  height: 100%;
}

.body {
  flex: 1 0 0;
  padding-left: 10px;
  overflow: hidden;
}

.body .top {
  display: flex;
  justify-content: space-between;
}

.body .top .username {
  font-size: 14px;
  color: #333;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.body .top .time {
  font-size: 12px;
  color: #999;
}

.content {
  padding: 5px 0;
  width: 100%;
  font-size: 12px;
  color: #666;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
