
.chatGroupWrapper {
  position: absolute;
  top: 19px;
  right: 215px;
  width: 280px;
  box-shadow: 1px 1px 50px rgb(0 0 0 / 30%);
  background: #fff;
  border: 1px solid #ddd;
  z-index: 1200;
}

/* .open .left::after {
  border-top: none;
  border-bottom: 8px solid #666;
}

.open .listLayer {
  height: 500px;
} */

.header {
  display: flex;
  height: 50px;
  border-bottom: 1px solid #e8e8e8;
  background-color: #f8f8f8;
  justify-content: center;
  align-items: center;
  padding: 0 8px;
}
.left {
  position: relative;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

/* .left::after {
  position: absolute;
  display: block;
  right: 10px;
  content: ' ';
  width: 0;
  height: 0;
  border-top: 8px solid #666;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
} */

.listLayer {
  display: flex;
  flex-direction: column;
  height: 500px;
  overflow: hidden;
  transition: all .5s;
}

.listLayer > ul {
  flex: 1 0 0;
}