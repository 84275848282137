.previewLayer {
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  z-index: 10001;
}

.maskLayer {
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(55, 55, 55, .6);
  z-index: 10078;
}

.imageLayer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.image {
  max-width: 900px;
  max-height: 100%;
  z-index: 10079;
}
