.main_layout {
  height: 100%;
  display: flex;
  flex-direction: column;

  .body_bar {
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: stretch;

    .content_bar {
      width: 100%;
    }

    .suspense-layer {
      position: relative;
      display: flex;
      flex-direction: column;
      flex: 1;
      margin: 10px;
      padding: 20px;
      background-color: #fff;
      overflow: auto;
    }
  }
}
