.loading {
  animation: loading 2s infinite linear;
}

.lazyImg {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@keyframes loading {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
 }